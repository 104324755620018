










import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component } from "vue-property-decorator";

import { Seminar } from "@/core/models";

@Component
export default class SeminarUsers extends Vue {
  @Getter("seminars/loading") loading!: boolean;
  @Getter("seminars/adding") adding!: boolean;
  @Getter("seminars/selected") seminar?: Seminar;

  @Action("seminars/get") getSeminar!: (d: any) => Promise<void>;
  @Action("displaySnackbar") displaySnackbar!: (m: string) => void;

  created() {
    const { id } = this.$route.params;
    this.getSeminar({ id: Number(id) });
  }
}
